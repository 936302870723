<template>
    <span class="butDel" @click="delInfo">

        <slot></slot>

    </span>
</template>
  
<script>
  export default {
    name: 'butDel',
    props: {
        item:{
			type:Object,
			default:() => ({})
		},

		itemKey:{
			type:String,
			default:'id'
		},

		way:{
			type:Boolean,
			default:true
		},

		type:{
			type:String,
			default:'el' // vex
		},

		mianPage:{
			type:String,
			default:'mianPage'
		},

		url:{
			type:String,
			default:''
		},

		refObj:{
			type:Object,
			default:() => ({})
		},

		can:{
			type:Object,
			default:() => ({})
		},

		msg:{
			type:String,
			default:'删除'
		},
    },

    data(){

        return {
            
        }
    },

    created(){
    },

    methods:{
		// 删除
		delInfo(){
			var that = this;
			var infoId = [];
			if(that.way){
				// 全选
				var dataAll = '';
				if(that.type == 'el'){
					dataAll = that.refObj.$refs[that.mianPage+'TableObj'].getSelectionRows();
				}else{
					dataAll = that.refObj.$refs[that.mianPage+'TableObj'].getCheckboxRecords();
				}

				for(var ss in dataAll){
					infoId.push(dataAll[ss].id);
				}
			}else{
				// 单个
				infoId.push(that.item[that.itemKey]);
			}

			if(infoId.length <= 0){
				that.util.alert({
					cancel:false,
					value:'请选择要'+that.msg+'的数据？',
				});
				return false;
			}

			that.util.alert({
				value:'您确定要'+that.msg+'吗？',
				success:function(){
					that.can.infoId = infoId;
					that.ajax(that.can);
				}
			});

		},

		ajax(data = {}){
			var that = this

			if(that.formSubOff){
				return false;
			}
			that.formSubOff = true;

			that.util.request({
				url:that.url,
				data:data,
				success(res){
					that.formSubOff = false;
					if(res.code == 200){

						that.refObj.$refs[that.mianPage].getData();
						that.$emit('ruleDel', 0);

						that.util.alert({
							cancel:false,
							value:res.msg,
						});
					}else{
						that.util.alert({
							type:'error',
							cancel:false,
							value:res.msg,
						});
					}
				},
				fail(res){ 
					console.log('nav_fail',res)
				}
			})
		},
    }
  }
  </script>
  
  <style  lang="less">
  .butDel{
	display: inline;
  }
  </style>
  